<template>
  <div class="page" v-loading="loading">

    <Nav activeIndex="-1"></Nav>

    <el-row justify="start">
      <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18">

        <blockquote v-if="dataList.length<=0">
          <el-col :span="24">
            <!-- 信息------找不到内容 -->
            <el-result icon="info" title="정보" subTitle="컨텐츠를 찾을 수 없음">
              <template slot="extra">
                <el-button type="primary" size="medium">back</el-button>
              </template>
            </el-result>
          </el-col>
        </blockquote>
        <blockquote v-else>
          <div v-for="(item,index) in dataList" :key="index">
            <div class="item">

              <el-tooltip class="item" effect="dark" :content="item.title" placement="bottom-start">

                <!-- 视频详细 -->
                <router-link v-if="item.line==0" :to="'VideoDetail?id='+item.id">
                  <h3 class="one_line_hidden">{{ item.title }}</h3>
                </router-link>

                <!-- 直接播放视频 torrent详细 -->
                <router-link v-if="item.line==1||item.line==2" :to="'View?id='+item.id">
                  <h3 class="one_line_hidden">{{ item.title }}</h3>
                </router-link>

              </el-tooltip>
              <div>

                <div class="content">
                  {{ item.intro }}
                </div>

                <!-- <el-row justify="start" class="bottom">
                  <el-col :span="15" class="one_line_hidden">
                    출처
                  </el-col>
                  <el-col :span="9" justify="end" class="one_line_hidden">
                    <i class="el-icon-link"></i>

                    <span style="padding-left: 5px;" v-if="item.line==0||item.line==1">
                      {{ item.url }}
                    </span>

                    <span style="padding-left: 5px;" v-if="item.line==2">
                      torrent
                    </span>

                  </el-col>
                </el-row> -->

              </div>

              <!-- <el-collapse accordion>
                <el-collapse-item title="m3u8">
                  <div>http://</div>
                </el-collapse-item>
                <el-collapse-item title="player">
                  <div>url</div>
                </el-collapse-item>
              </el-collapse> -->

            </div>
          </div>
        </blockquote>

      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" justify="end">
        <el-row>
          <el-col :span="24" style="padding: 0px 2px;" v-for="(item,index) in adList" :key="index">

            <a :href="item.url" target="_blank"><img style="width: 100%;" :src="item.img" alt=""></a>

          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <div style="padding-top: 20px;" >
      <el-pagination
        background
        layout="prev, pager, next"
        @prev-click="prevPage"
        @next-click="nextPage"
        @current-change="currentChange"
        :pager-count="5"
        :page-size="10"
        :current-page="pagePage"
        :total="Total">
      </el-pagination>
    </div>

    <el-row>
      <el-col :xs="12" :sm="6" :md="4" :lg="3" :xl="3" style="padding: 10px;" v-for="(item,index) in otherList" :key="index">

        <el-card :body-style="{ padding: '0px',width:'100%',height:'205px' }">
          <!-- <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" class="image"> -->
          <span @click="goTo(item.line==0?'/VideoDetail?id='+item.id:'/view?id='+item.id)">
            <el-image name="img_video" class="el_image img_video" :src="item.image" fit="contain">
              <div slot="error" class="image-slot">
                <img src="@/assets/no_img.png" fit="contain">
              </div>
              <div slot="placeholder" class="image-slot">
                <img src="@/assets/loading.gif" fit="contain">
              </div>
            </el-image>
          </span>
          <div style="padding: 10px;">
            <div style="font-size: 0.7rem;width:100%;">{{ item.title }}</div>
          </div>
        </el-card>

      </el-col>
    </el-row>

  </div>
</template>
<script>
import Nav from '@/components/Nav.vue'
export default {
  name: 'PageSearch',
  components: {
    Nav
  },
  data () {
    return {
      loading: true,
      adList: [],
      dataList: [],
      pageKeys: '',
      pagePage: 0,
      pageTotal: 0,
      Total: 0,
      otherList: []
    }
  },
  created () {
    // console.log(this.$route.query.keys) // 输出: 123

    this.pagePage = parseInt(this.$route.query.page)
    this.pageKeys = this.$route.query.keys

    this.loadPage('keys=' + this.pageKeys + '&page=' + this.pagePage)
  },
  watch: {
    // 监听路由对象 $route 的变化
    $route: {
      handler: function (to, from) {
        // 路由变化时执行的操作
        this.onRouteChange(to, from)
      },
      // 设置为深度监听
      deep: true
    }
  },
  methods: {
    loadPage (Query) {
      this.dataList = [], this.adList = [], this.otherList = []
      const that = this; let dataList = this.dataList; const adlist = this.adList; const otherList = this.otherList

      this.httpinfo.GetInfo(this.baseURL + 'search?' + Query, function (e) {
        if (e.data.code == 0) {
          that.pageTotal = e.data.pagecount
          that.Total = e.data.count

          e.data.adlist.forEach(element => {
            adlist.push(element)
          })

          e.data.data.forEach(element => {
            dataList.push(element)
          })

          e.data.other.forEach(element => {
            otherList.push(element)
          })

          dataList = []
        } else {
          that.$message.error(e.data.msg)
        }

        that.loading = false
      })
    },
    prevPage (e) {
      // console.log(e)
    },
    nextPage (e) {
      // console.log(e)
    },
    currentChange (e) {
      // console.log(e)
      // console.log(this.$router.currentRoute.fullPath)
      this.goTo('/PageSearch?keys=' + this.pageKeys + '&page=' + e)
    },
    goTo (path) {
      if (path !== this.$router.currentRoute.fullPath) {
        this.loading = true
        this.$router.push(path)
      }
    },
    onRouteChange (to, from) {
      // // 你的逻辑代码
      // // console.log('Route changed from', from.path, 'to', to.path);
      this.loadPage('keys=' + to.query.keys + '&page=' + to.query.page)
    }
  }
}
</script>
<style>
.item{
  color: #409EFF;
  padding: 0px;
  text-align: left;
}
.item .content{
  color: #999;
  padding-left: 6px;
  word-wrap:break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 5.5em; /* 行高1.1em的时候，3em就是3行，5.5em就是5行 */
}
.item .bottom{
  padding: 10px 0;
  color: #aaa;
  font-size: 9pt;
}

.subtitle{
  text-align: left;
  min-width: 150px;
}
.el_image img{
  padding: 0;
  width: 120px;
  height: 160px;
}
</style>
