<template>
  <div class="">
    <el-menu :collapse="collapse" :style="unline==false?'border-bottom: none !important;':''" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-menu-item index="1"><i class="el-icon-film" style="color: #409eff;margin: -3px;"></i> 다시보기</el-menu-item>
      <!-- <el-submenu index="1">
        <template slot="title">영상</template>
        <el-menu-item index="1-0">모두</el-menu-item>
        <el-menu-item index="1-1">드라마</el-menu-item>
        <el-menu-item index="1-2">시사예능</el-menu-item>
        <el-menu-item index="1-3">애니메이션</el-menu-item>
        <el-menu-item index="1-4">영화</el-menu-item>
        <el-menu-item index="1-5">완결한드</el-menu-item>
        <el-menu-item index="1-6">해외드라마</el-menu-item>
      </el-submenu> -->
      <!-- <el-menu-item index="6"><i class="el-icon-star-on" style="color: #409eff;margin: -3px;"></i> 탐색 </el-menu-item> -->
      <!-- <el-menu-item index="7"><img style="width: 18px;" src="@/assets/images/live.png" alt="TV생방송"> TV생방송</el-menu-item> -->
      <!-- <el-menu-item index="8"><i class="el-icon-football" style="color: #409eff;margin: -3px;"></i> 스포츠 생방송</el-menu-item> -->
      <!-- <el-menu-item index="9"><i class="el-icon-chat-line-round" style="color: #409eff;margin: -3px;"></i> 업체홍보</el-menu-item> -->
    </el-menu>

  </div>
</template>

<script>
export default {
  name: 'CompNav',
  props: {
    activeIndex: String,
    unline: Boolean,
    collapse: Boolean
  },
  methods: {
    handleSelect (key, keyPath) {
      // console.log(key, keyPath);
      switch (key) {
        case '1':// 视频
          this.goTo('/PageType?type=1-0&page=1')
          break
        case '6':// 导航
          this.goTo('/PageNavigation')
          break
        case '7':// 电视直播
          this.goTo('/TV-live')
          break
        case '8':// 体育直播
          this.goTo('/TV-Sport')
          break
        case '9':// 信息板
          this.goTo('/Infor-Board')
          break
        default:
          this.goTo('/PageType?type=' + key + '&page=1')
      }
    },
    goTo (path) {
      if (path !== this.$router.currentRoute.fullPath) {
        this.$router.push(path)
      }
    }
  }
}
</script>
<style>
el-menu-item{
  padding: 500px;
}
</style>
